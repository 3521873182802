import React, {useEffect, useState} from "react";
import {Await, Link} from 'react-router-dom';
import SocialLinks from './social-links';
import FadeAnimation from "./FadeAnimation";
import BlogComponent from "./BlogComponent";


const fetchVersion = async () => {
    try {
        //Gets The Info That fetchVersion api gets
        const response = await fetch('api/fetchVersion');

        //puts the data from the json into a constant called data that we can access
        const data = await response.json();
        return data;
        //Updates whatever frontend has the version id on it with both the commit hash and the commit date
    }catch (error){
        console.error("Error Fetching Version", error);
        return null;
    }

 };

function HomePage() {
    const [versionInfo, setVersionInfo] = useState(null);
    
    useEffect(() => {
        const getVersionInfo = async () => {{
            const data = await fetchVersion();
            if(data){
                setVersionInfo(data);
            }else{
                setVersionInfo({version: "N/A", date:"Unable To Fetch Date"})
            }        
        }}
        getVersionInfo()
    }, []);

    return (
        <FadeAnimation>
            <div>
                <section id="about" className="HomePagecard-card">
                    <Link to="about">
                        <h2>About Me</h2>
                        <p>A bit about Me And My Aspirations</p>
                    </Link>
                </section>

                <section id="projects" className="HomePagecard-card">
                    <Link to="projects">
                        <h2>Projects</h2>
                        <p>Some of the projects that I've been developing</p>
                    </Link>
                </section>

                <section id="skills" className="HomePagecard-card">
                    <Link to="skills">
                        <h2>Skills</h2>
                        <p>Languages and Backend APIs That I'm Proficient In</p>
                    </Link>
                </section>

                <div className="version-info">
                    <h3>Current Website Version:</h3>
                    {versionInfo ? (
                        <p>Current Commit Version Hash: {versionInfo.version} Commit Date: {versionInfo.date}</p>
                    ) : (
                        <p>Loading Version Info!</p>
                    )}
                </div>

                <h1 className="BlogTopOfPage">
                    {'Latest Blog Posts:'}
                </h1>
                <BlogComponent></BlogComponent>
            </div>
        </FadeAnimation>
    );
}

export default HomePage;