import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import './styles/blog.css'
import FadeAnimation from "./FadeAnimation";
import NoImage from "./Images/no-image-icon-23492.png";

const GhostApiUrl = "https://toms-blogs.ghost.io";
const apiKey = "85fd36df7bd4956eb5039857a4";  

const BlogComponent = () => {

    const{projectSlug} = useParams();
    const [blogPosts, setBlogPosts] = useState([]);
    const [error, setError] = useState(null);
    const projectLimit = 250;
    const homePageLimit = 4;
    console.log('Project Slug',projectSlug);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const limit = projectSlug ? projectLimit : homePageLimit;
                const response = await axios.get(`${GhostApiUrl}/ghost/api/v3/content/posts/`, {
                    params: {
                        key: apiKey,        
                        include:'authors',
                        order: 'published_at desc',
                        limit: limit,      
                        ...(projectSlug && {filter: `tag:${projectSlug}`,
                        })
                    },
                });
                
                console.log("API Response: ",response.data);

                setBlogPosts(response.data.posts); 
            } catch (err) {
                console.error('Error fetching posts:', err);
                setError('Failed to fetch blog posts');
            }
        };

        fetchBlogPosts();
    }, [projectSlug]);
 
    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <FadeAnimation>
            {blogPosts.length > 0 ? (
                <ul className="blog-list">
                    {blogPosts.map((post) => {

                        const title = post.title || "Untitled Post!";
                        const postSlug = post.slug || "no-slug";
                        const previewImage = post.feature_image ? post.feature_image : require("./Images/no-image-icon-23492.png");
                        const postAuthor = post.authors && post.authors.length >0 ? post.authors[0].name : "No Author";
                        const projectSlugToUse = projectSlug || "";
                        
                        
                        const postLink  = projectSlugToUse ? `projects/${projectSlugToUse}/blog/${postSlug}`:`/blog/${postSlug}`;
                        console.log('Preview Image', previewImage)
                        
                        return (
                                <li key={post.id} className="BlogPostPage">
                                    <Link to={`/projects/${projectSlug}/blog/${postSlug}`}>
                                        <img
                                            src={previewImage}
                                            alt={`Preview For  ${post.title}`}
                                            className={"preview-image"}
                                        />
                                        <p>By: {postAuthor}</p>
                                        <h2>{title}</h2>

                                    </Link>
                                </li>
                        );
                    })}
                </ul>
            ) : (
                <p className="BlogTopOfPage">There are currently no posts for this project.</p>
            )}
            </FadeAnimation>
        </div>
    );
}

export default BlogComponent;
