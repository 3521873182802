import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './styles/blog.css'
import {useNavigate} from 'react-router-dom';
import FadeAnimation from "./FadeAnimation";


const GhostApiUrl = "https://toms-blogs.ghost.io";  
const apiKey = "85fd36df7bd4956eb5039857a4";  

function FullBlogPostPage() {
    const {slug} = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                // Fetch the full post based on the slug
                const response = await axios.get(`${GhostApiUrl}/ghost/api/v3/content/posts/`, {
                    params: {
                        key: apiKey,
                        filter: `slug:${slug}`,
                    },
                });
                setPost(response.data.posts[0]);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching the post:', err);
                setError('Failed to fetch the blog post');
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);
    
    
    
    //This is Just a quick solution to get rid of video players annoying buttons for now!
    useEffect(() => {
        if(post){
            const videoButtons = document.querySelectorAll('.kg-video-player-container.kg-video-hide');
            const thisOtherRandomButton = document.querySelectorAll('.kg-video-large-play-icon');
           
            videoButtons.forEach((button) =>{
               button.remove();
                 });  
            
            thisOtherRandomButton.forEach((button) =>{
               button.remove();
                 });
            }
        }, [post]);

    useEffect(() => {
        console.log(post?.html)
    }, [post]);
    
    if (loading) {
        return <div></div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!post) {
        return <div>Post not found</div>;
    }
    
    
    return (
        <FadeAnimation>
            <div className="FullBlogPostPage">
                <a href="#" onClick={(e) => {
                    e.preventDefault();
                    handleGoBack();
                }}>Back</a>
                <h1>{post.title}</h1>
                <div className="full-blog" dangerouslySetInnerHTML={{__html: post.html}}/>
            </div>
        </FadeAnimation>
    );
}

export default FullBlogPostPage;
