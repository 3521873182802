import React from 'react';
import {useLocation} from 'react-router-dom';
import FadeAnimation from "./FadeAnimation";


function Header(){
    const location = useLocation();

    const pageData = {
         '/projects/cauldronchaos/blog': {
        headerMessage: "This is a final year project we're making at Salford Uni, these are blog posts containing my involvement and contributions to the project.",
        headerTitle: "Cauldron Chaos",
    },

    '/projects/simplechatapp/blog/': {
        headerMessage: "This is a simple chat app using websockets, a project I'm working on to learn backend skills!",
        headerTitle: "Simple Chat App",
    },

        '/projects/portfoliosite/blog': {
        headerMessage: "Take a look at behind the scenes of making this website!",
        headerTitle: "This Portfolio Site",
    },

        '/projects/breakoutmp/blog': {
        headerMessage: "Another project I did in Uni for my second year, here are some blog post of me going back to the project after a while to try and make it better.",
        headerTitle: "Breakout Multiplayer",
    },
        '/': {
        headerMessage: "Welcome To My Portfolio Website, Please Click A Link Below To Get Started! \n", 
            websiteUnderConstruction: "This Website Is Under Heavy Construction! - I’m learning React JS and building the site—thanks for your patience!",
        headerTitle: "Tomos Jones",
        },

        '/skills': {
        headerMessage: "For now i have a graph showing my most used programming languages fetched directly from my github!",
        headerTitle: "My Skills",
        },

        '/projects': {
            headerMessage: "Click on a project for a range of blog posts relating to it.",
            headerTitle: "My Projects!",
        },

        '/about': {
            headerMessage: "Here is a bit about me and My Aspirations.",
            headerTitle: "About Me!",
        },
    };

    const defaultData = {
        headerMessage:"",
        headerTitle:"Tomos Jones",
    }

    const{headerMessage, websiteUnderConstruction, headerTitle} = pageData[location.pathname] ||defaultData;

    return (
        <FadeAnimation>
            <div className="TopOfPage">
                <h1>{headerTitle}</h1>
                <p>{headerMessage}</p>
                <p>{websiteUnderConstruction}</p>
            </div>
        </FadeAnimation>

    );
}

export default Header;