import React from "react";
import {Link, useLocation} from "react-router-dom";
import {NavLink} from "react-router-dom";
import "./styles/navbarstyle.css";



function Navbar() {

    const location = useLocation();

    return (
        <header className="TopOfPage">
            <nav className={"navbar"}>
                <ul>
                    <li>
                        <NavLink to="/" className={({isActive}) => (isActive ? "active" : "")}
                        >Home
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/projects" className={({isActive}) => (isActive ? "active" : "")}
                        >Projects
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/skills" className={({isActive}) => (isActive ? "active" : "")}
                        >Skills
                        </NavLink>
                    </li>

                    <li>
                        <NavLink to="/about" className={({isActive}) => (isActive ? "active" : "")}
                        >About
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Navbar;