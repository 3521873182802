import axios from "axios";
import { Bar } from 'react-chartjs-2';
import {useEffect, useState} from "react";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const apiUrl = '/api/FetchRepoLanguages';


 function RepoLanguageBarGraph() {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
      async function fetchLanguages(){
          try{
              const response = await axios.get(apiUrl);
              const data = response.data;

              console.log("Fetched Data: ", data);

              if(data && data.languages && typeof data.languages === 'object'){
                  setChartData({
                      labels: Object.keys(data.languages),
                      datasets: [{
                          label: "Number Of Repositories",
                          data:Object.values(data.languages),
                          backgroundColor: "rgba(0, 0, 0, 0.2)",
                          borderColor: "rgba(0, 0, 0, 0.2)",
                          borderWidth: 1
                      }
                      ],
                  });
              }else{
                  throw new Error("Invalid Format.");
              }

          }catch(error){
              console.error(error);
              setError("Error Loading Data!")
          }finally {
              setLoading(false);
          }
      }


      fetchLanguages();
  },[]);

  if (loading) {
      return <div>Loading...</div>;
  }


  if(error){
      return <div>{error}</div>;
  }

  return(
      <div>
         <h2>Programming Languages In My Github Repos!</h2>
          {chartData.labels ? (
              <Bar data={chartData} options={{responsive: true}} />
          ) : (
              <p>No Data Available!</p>
          )}
      </div>
  );

}

export default RepoLanguageBarGraph;