import React from "react";
import RepoLanguageBarGraph from "./RepoLanguageBarGraph";
import FadeAnimation from "./FadeAnimation";


function Skills() {
    return (
        <div className="BlogTopOfPage">
            <FadeAnimation>
                <RepoLanguageBarGraph />
            </FadeAnimation>
        </div>
    )



}

export default Skills;