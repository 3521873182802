import React from "react";
import XIcon from './Images/XLogoWhite.jpg'
import GithubIcon from './Images/github-mark-white.jpg'
import LinkedInIcon from './Images/In-Blue-96.jpg'
import './styles/generalwebstyle.css'


function SocialLinks() {
    return (
        <footer className="bottomOfPage">
            <div className="social-links">
                <a href="https://github.com/EasyFez" target="_blank">
                    <img src={GithubIcon} alt="Github Icon" />
                </a>
                <a href="https://www.linkedin.com/in/tomos-jones-633468176/" target="_blank">
                    <img src={LinkedInIcon} alt={"LinkedIn Icon"} />
                </a>
                <a href="https://x.com/TomosWJ15" target="_blank">
                    <img src={XIcon} alt="X Logo" />
                </a>
            </div>
        </footer>
    )
}

export default SocialLinks;