import React, {useEffect, useState} from 'react';
import FadeAnimation from "./FadeAnimation";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";


const GhostApiUrl = "https://toms-blogs.ghost.io";
const apiKey = "85fd36df7bd4956eb5039857a4";

function ProjectDetail() {
    const {slug} = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchPost = async () => {
            try {
                // Fetch the full post based on the slug
                const response = await axios.get(`${GhostApiUrl}/ghost/api/v3/content/posts/`, {
                    params: {
                        key: apiKey,
                        filter: `tag:${slug}+tag:project-details`,
                    },
                });
                setPost(response.data.posts[0]);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching the post:', err);
                setError('Failed to fetch the blog post');
                setLoading(false);
            }
        };

        fetchPost();
    }, [slug]);

    if (loading) {
        return <div></div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!post) {
        return (
            <div className="TopOfPage"><h2>Details Page not found</h2>
            <a href="#" onClick={(e) => {e.preventDefault();handleGoBack();}}> Back </a>
            </div>
    )

    }

    return (
        <div className="FullBlogPostPage">
            <a href="#" onClick={(e) => {
                e.preventDefault();
                handleGoBack();
            }}>Back</a>
            <h1>{post.title}</h1>
            <div dangerouslySetInnerHTML={{__html: post.html}}/>
        </div>
    );
}

export default ProjectDetail;