import React from "react";
import "./styles/projectstyling.css";
import "./styles/generalwebstyle.css";
import {Link} from "react-router-dom";
import CauldronChaosIcon from "./Images/CauldronChaos.png"
import NoImage from "./Images/no-image-icon-23492.png"
import FadeAnimation from "./FadeAnimation";

function ProjectCards() {
    return (
        <FadeAnimation>
            <div className="projects">
                <Link to="/projects/cauldronchaos/blog" className="projects-card">
                    <img
                        src={CauldronChaosIcon}
                        alt="Thumbnail of the Project"
                        className="project-image"
                    />
                    <div className="project-details-title">
                        <h2>Cauldron Chaos</h2>
                    </div>
                </Link>

                    <Link to="/projects/simplechatapp/blog" className="projects-card">
                        <img
                            src={NoImage}
                            alt="Thumbnail of the Project"
                            className="project-image"
                        />
                        <div className="project-details-title">
                            <h2>Simple Chat App</h2>
                        </div>
                    </Link>

                    <Link to="/projects/portfoliosite/blog" className="projects-card">
                        <img
                            src={NoImage}
                            alt="Thumbnail of the Project"
                            className="project-image"
                        />
                        <div className="project-details-title">
                            <h2>My Portfolio Site</h2>
                        </div>
                    </Link>


                    <Link to="/projects/breakoutmp/blog" className="projects-card">
                        <img
                            src={NoImage}
                            alt="Thumbnail of the Project"
                            className="project-image"
                        />
                        <div className="project-details-title">
                            <h2>Breakout Multiplayer</h2>
                        </div>
                    </Link>
                {/*<div className="projects">
                <div className="projects-card" onClick="toggleDetails(this)">
                    <img src="images/cauldronchaos.jpg" alt="Thumbnail of the Project" className="project-image"/>
                    <div className="project-details-title">
                        <h2>Node JS Simple Chat App</h2>
                        <div classNa    me="project-details">
                            <p>Websocket Server</p>
                        </div>
                    </div>
                </div>
            </div>*!/*/}
        </div>
</FadeAnimation>

)


}

export default ProjectCards;